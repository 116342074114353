<template>
  <div>
    <div class="sa-dialog-body sa-video-call">
      <div class="sa-phone-hdr">
        <div class="sa-phone-hdr-tit">
          <strong>{{ apnInfo.p_fn }} {{ apnInfo.p_ln }}</strong>
          <small> {{ callingText }} </small>
          <small v-if="showAlert">
            Please Disconnect the call to go back
          </small>
        </div>
        <div id="remoteTrack"></div>
      </div>
      <div class="sa-phone-call">
        <figure class="sa-avtar sa-avtar-circle sa-avtar-xl sa-avtar-center">
          <img
            alt="doctor"
            :src="apnInfo.p_img == '' ? imgUrl : apnInfo.p_img"
            class="sa-img-cover"
          />
        </figure>
      </div>
      <ul class="sa-phone-list">
        <li class="sa-dark-gray-bg" @click="toggleMic">
          <i
            class="sa-white-text"
            :class="micFlag ? 'icon-micon' : 'icon-mute'"
          ></i>
        </li>
        <li class="sa-red-bg" @click="disconnectCall(true)">
          <i class="icon-call sa-white-text"></i>
        </li>
        <!-- <li class="sa-dark-gray-bg" @click="toggleSpeaker">
          <i class="icon-volume-indicator sa-white-text"></i>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import { imageStaticUrl } from "../../../../config/default.json";
import Twilio, {
  connect,
  createLocalTracks,
  createLocalAudioTrack,
  createLocalVideoTrack,
  AudioLevel,
} from "twilio-video";
export default {
  props: ["showAlert"],
  data() {
    return {
      imgUrl: imageStaticUrl,
      switchMicBtn: false,
      micFlag: true,
      speakerFlag: true,
      timer: "00:00",
      callingText: "Currently Speaking ...",
      interval: null,
      unAcceptedcall: null,
      acceptCall: null,
      callReject: false,
      callstartflag: false,
      Connected: false,
      calling: true,
      permissionDenied: false,
    };
  },
  created() {
    this.activeRoom = null;
    // this.socket = null;
    // this.socketId = "";
    this.doctorToken = null;
    this.patientToken = null;
    this.userMediaStream = null;
    this.ringBackTone = null;
  },
  async mounted() {
    await this.patientTwilio();
      await this.userMedia();
      await this.doctorTwilio();
      await this.videoCallTwilio()
      let _self = this
       this.callingLoop = setInterval(() => {
         _self.videoCallTwilio();
       }, 7000)
  },
  async beforeUnmount() {
    console.log("unmounted");
    await this.disconnectUserMedia();

    if (this.unAcceptedcall) {
      clearTimeout(this.unAcceptedcall);
    }
    if (this.acceptCall) {
      clearTimeout(this.acceptCall);
    }

    if (this.interval) {
      clearInterval(this.interval);
    }

    // if (this.socket) {
    //   this.socket.off();
    //   this.socket.close();
    // }
    //  this.twilioConnect();
  },

  methods: {
    leaveRoomIfJoined() {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }
    },
    switchMic: function () {
      this.switchMicBtn = !this.switchMicBtn;
    },
    userMedia: async function () {
      try {
        this.userMediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.permissionDenied = true;
        console.log("NAHL", this.userMediaStream);
      } catch (error) {
        this.permissionDenied = false;
        location.reload();
        console.log("AJmal", error);
        this.$parent.wifiFlag = false;
      }
    },
    disconnectUserMedia() {
      if (this.userMediaStream) {
        this.userMediaStream.getTracks().forEach(function (track) {
          console.log(track, "........");
          track.stop();
        });
        console.log(this.userMediaStream, "checking deleted stream");
      }
    },
    toggleMic: function () {
      if (this.activeRoom) {
        let audioTracks = this.activeRoom.localParticipant.audioTracks.entries();
        /*this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
        console.log("BALA",this.activeRoom.localParticipant.audioTracks)*/
        for (let [key, publication] of audioTracks) {
          if (publication) {
            if (this.micFlag) {
              publication.track.disable();
            } else {
              publication.track.enable();
            }
          }
        }
        this.micFlag = !this.micFlag;
      }
    },
    disconnectCall (val) {
        this.$emit("onDisconnect", true);
        this.activeRoom.disconnect();
    },

    async doctorTwilio() {
      console.log(this.apnInfo.uid);
      let body = {
        username: this.apnInfo.uid,
        roomname: this.apnInfo.apnt_id,
      };
      this.doctorToken = await this.$store.dispatch(
        "appointments/GET_TWILIO_TOKEN",
        body
      );
      console.log(this.doctorToken, "doctor token");
    },
    async patientTwilio() {
      let body = {
        username: this.apnInfo.createdby,
        roomname: this.apnInfo.apnt_id,
      };
      this.patientToken = await this.$store.dispatch(
        "appointments/GET_TWILIO_TOKEN",
        body
      );
      console.log(this.patientToken, "patient token");
    },
     socketDisconnect() {
      console.log("socket disconnect...");
    },
    connection() {
      setTimeout(() => {
        this.calling = false;
        this.interval = setInterval(() => {
          this.timer = moment(this.timer, "mm:ss")
            .add(1, "seconds")
            .format("mm:ss");
        }, 1000);
      }, 3000);
    },
    videoCallTwilio() {
      const videocallRequestBody = {
              uid: this.apnInfo.createdby,
              doc_id: this.apnInfo.uid,
              data: {
                type: "audiocall",  
                roomName: this.apnInfo.apnt_id,
                token: this.patientToken.data.token,
                docName: this.apnInfo.prvdr_fname + " " + this.apnInfo.prvdr_lname,
                docProfile: this.apnInfo.prvdr_img,
                name: this.apnInfo.createdby,
                sid: this.$parent.socket.id,
                uid: this.apnInfo.createdby,
                provider: this.$parent.videoData.data.data.provider,
                utid: this.apnInfo.utid
              },
            };
            this.videocallPayload = videocallRequestBody
             this.$store
            .dispatch("appointments/SOCKET_VIDEO_CALL", videocallRequestBody)
    },
    clearDialer() {
      if(this.callingLoop)
      clearInterval(this.callingLoop);
    },
    async twilioConnect() {
      let _self = this;
      let connectOptions = {
        name: this.apnInfo.apnt_id,
        audio: true
      };
      this.clearDialer()

      await Twilio.connect(this.doctorToken.data.token, connectOptions).then(
        function (room) {
          console.log(room, "room");
          _self.activeRoom = room;

          // Attach the Tracks of all the remote Participants.
          room.participants.forEach(function (participant) {
            _self.participant = participant;
            let previewContainer = document.getElementById("remoteTrack");
            // VueThis.attachParticipantTracks(participant, previewContainer);
            _self.participant.on("trackSubscribed", (track) => {
              if (track && track.kind != "data") {
                trackSubscribed(previewContainer, track);
              }
            });
            _self.participant.on("trackUnsubscribed", trackUnsubscribed);
            _self.participant.tracks.forEach((publication) => {
              if (publication.isSubscribed) {
                if (track && track.kind != "data") {
                  trackSubscribed(previewContainer, publication.track);
                }
              }
            });
          });

          // When a Participant joins the Room, log the event.
          room.on("participantConnected", (participant) => {
            // _self.Connected = true;
            // _self.connection();
            console.log("participant connected: ", participant);
            _self.participant = participant;
            let previewContainer = document.getElementById("remoteTrack");
            _self.participant.on("trackSubscribed", (track) => {
              if (track && track.kind != "data") {
                trackSubscribed(previewContainer, track);
              }
            });
            // _self.participant.on("trackUnsubscribed", trackUnsubscribed);
            _self.participant.on("trackUnsubscribed", (track) => {
              if (track && track.kind == "audio") {
                trackUnsubscribed(track);
              }
            });
            _self.participant.tracks.forEach((publication) => {
              if (publication.isSubscribed) {
                if (track && track.kind != "data") {
                  trackSubscribed(previewContainer, track);
                }
              }
            });
          });

          createLocalAudioTrack().then((track) => {
            // let localMediaContainer = document.getElementById("localTrack");
            if (track.kind == "video") {
              track.disable();
            }
            // localMediaContainer.appendChild(track.attach());
            room.localParticipant.publishTrack(track);
          });

          room.on("participantDisconnected", function (participant) {
            console.log("remote participant disconnected--->", participant);
            disconnected(room);
          });

          room.on("disconnected", disconnected);
        }
      );

      function disconnected(room) {
        // _self.Connected=false
        console.log("disconnected", room);
        let audioMap = room.localParticipant.audioTracks.entries();
        for (let [key, audio] of audioMap) {
          console.log("videoTrackvideoTrack", audio);
          let audioTrack = audio.track;
          audioTrack.mediaStreamTrack.stop();
          audioTrack.detach().forEach((element) => element.remove());
          room.localParticipant.unpublishTrack(audioTrack);
        }
        _self.$parent.wifiFlag = false;
        console.log("resources cleared");
      }
      function trackSubscribed(div, track) {
        // console.log("tracks ", track);
        // let ele = document.createElement("audio");
        // ele.id = "remoteParticipantAudio";
        // if (track.kind == "video") {
        //   ele = document.createElement("video");
        //   ele.id = "remoteParticipantVideo";
        // }
        // track.attach(ele);
        // let remoteTrack = document.getElementById("remoteTrack");
        // let videoEle = document.getElementById("remoteParticipantVideo");
        // console.log("check ", videoEle);
        // if (videoEle) {
        //   remoteTrack.removeChild(videoEle);
        // }
        // remoteTrack.appendChild(ele);
        if (track && track.kind == "audio") {
          div.appendChild(track.attach());
        }
      }
      function trackUnsubscribed(track) {
        console.log("tracks : ", track);

        // detaching video tracks
        // if (track && track.kind == "video") {
        //   if (
        //     [..._self.activeRoom.localParticipant.videoTracks.values()][0].track
        //   ) {
        //     let videoTrack = [
        //       ..._self.activeRoom.localParticipant.videoTracks.values(),
        //     ][0].track;
        //     videoTrack.stop();
        //     videoTrack.detach().forEach((element) => element.remove());
        //     _self.activeRoom.localParticipant.unpublishTrack(videoTrack);
        //     track.detach().forEach((element) => element.remove());
        //   }
        // }

        // detaching audio tracks
        if (track && track.kind == "audio") {
          let audioTrack = [
            ..._self.activeRoom.localParticipant.audioTracks.values(),
          ][0].track;
          audioTrack.stop();
          audioTrack.detach().forEach((element) => element.remove());
          _self.activeRoom.localParticipant.unpublishTrack(audioTrack);
          track.detach().forEach((element) => element.remove());
        }
      }
    },
    },
  computed: {
    apnInfo: function () {
      return JSON.parse(localStorage.getItem("activeAppointmentObj"));
    },
  },
};
</script>
