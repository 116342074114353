<template>
  <div v-if="undefined != apnInfo && null != apnInfo">
    <div class="sa-dialog" @click.self="$emit('close-pop')">
      <div class="sa-dialog-body sa-fixed-bottom sa-fixed-height70" :draggable="true" >
        <button class="sa-close-line" @click="$emit('close-pop')"></button>
        <div v-if="selectedPack.packageDetails && selectedPack.packageDetails.length">
          <div v-for="packs in selectedPack.packageDetails" :key="packs.id">
            <h5 class="sa-mb10">{{ packs.name }}</h5>
            <div class="sa-card sa-card-info sa-card-no-border">
              <h6 class="sa-mb10">{{ $t("instructions") }}</h6>
              <ul class="sa-unorder-list">
                <li v-for="(ins, i) in selectedPack.instructions" :key="i" >
                  {{ ins }}
                </li>
              </ul>
            </div>
            <div v-if="packs.addl_tests_included && packs.addl_tests_included.length">
              <h6 class="sa-mb10">{{ $t("ExtraTest") }}</h6>
              <ul class="sa-unorder-list">
                <li v-for="(ext, j) in packs.addl_tests_included" :key="j" >
                  {{ ext }}
                </li>
              </ul>
            </div>
            <br />
            <div v-if="packs.tests_included">
              <h6 class="sa-mb10">{{ $t("TestSelectpkg") }}</h6>
              <ul class="sa-unorder-list">
                <li v-for="(tst, k) in packs.tests_included" :key="k" >
                  {{ tst }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="selectedPack.testDetails && selectedPack.testDetails.length">
          <div class="sa-card sa-card-info sa-card-no-border" v-if="selectedPack.instructions && selectedPack.instructions.length">
            <h6 class="sa-mb10">{{ $t("instructions") }}</h6>
            <ul class="sa-unorder-list">
              <li v-for="(ins, i) in selectedPack.instructions" :key="i">
                {{ ins }}
              </li>
            </ul>
          </div>
          <div v-for="(tsk, j) in selectedPack.testDetails" :key="j">
            <!-- <p class="sa-black-text">{{ tsk.name }}</p> -->
            <ul class="sa-unorder-list">
              <li v-for="(incln, m) in tsk.tests_included" :key="m">
                {{ incln }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t("noTaskFound") }}
  </div>
</template>
<script>
export default {
  props: ["selectedPack"],
  data() {
    return {};
  },
  computed: {
    apnInfo: function() {
      return this.$store.getters["appointments/getActiveAppointments"].data.info;
    },
  },
  mounted() {},
  methods: {},
};
</script>
