<template>
    <div class="sa-dialog" >
    <ringer v-on:endCall="endCall" v-if="ringer == true" />
    <div :class="ringer ? 'sa-d-none':''" >
    <agora-client
      ref="agora"
      v-on:onDisconnect="disconnectCall"
      v-if="videoData && videoData.data.data.provider === 'agora'"
    />
    <twilio-client
      v-on:onDisconnect="disconnectCall"
      ref="twilio"
      v-if="videoData && videoData.data.data.provider === 'twilio'"
    />
  </div>
  </div>
</template>

<script>
import Ringer from "./Ringer.vue"
import TwilioClient from "./twilio-client/TwilioClient.vue";
import AgoraClient from "./agora-client/AgoraClient.vue";
import { socketHub } from "../../../config/default.json";
import io from "socket.io-client";
import uniqid from "uniqid";
export default {
  name: "VideoCall",
  components: {
    Ringer,
    AgoraClient,
    TwilioClient,
  },
  data() {
    return {
      switchMicBtn: false,
      micFlag: true,
      speakerFlag: true,
      timer: "00:00",
      callingText: "calling...",
      interval: null,
      unAcceptedcall: null,
      acceptCall: null,
      callReject: false,
      callstartflag: false,
      Connected: false,
      calling: true,
      permissionDenied: false,
      socketId: null,
      videoData: null,
      ringer: true,
    };
  },
  computed: {
    isConnected() {
      const status = this.$store.getters.callStatus;
      return status == "connected" || status == "inprogress";
    },
    provider() {
      const payload = this.$store.getters.videocallPayload;
      return payload.provider || "agora";
    },
    apnInfo: function () {
      return JSON.parse(localStorage.getItem("activeAppointmentObj"));
    },
  },
  async mounted() {
    let permissions = await this.userMedia();
    if(permissions){
      this.socketInit();
      this.startcall();
    }
  },
  methods: {
    async startcall() {
      this.$store
        .dispatch("appointments/GET_VIDEOCALL_TOKEN", {
          username: this.apnInfo.createdby,
          roomname: this.apnInfo.apnt_id,
        })
        .then((res) => {
          if (res.data.status === 1000) {
            this.videoData = res;
            this.Connected = true;
          } else {
            alert(JSON.stringify(res.data));
          }
        });
    },
    async socketInit() {
      const userId = this.apnInfo.uid;
      console.log(userId, "1");
      let did = uniqid();

      console.log(`uid=${userId}&did=${did}`);
      this.socket = io(socketHub, {
        transports: ["websocket"],
        query: `uid=${userId}&did=${did}`
      });
      if (this.socket) {
        this.socket.on("connect", this.socketConnect);
        this.socket.on("disconnect", this.socketDisconnect);
      }
    },
    // getLocalStream() {
    //   navigator.mediaDevices
    //     .getUserMedia({ video: false, audio: true })
    //     .then((stream) => {
    //       window.localStream = stream; // A
    //       window.localAudio.srcObject = stream; // B
    //       window.localAudio.autoplay = true; // C
    //     })
    //     .catch((err) => {
    //       console.log("u got an error:" + err);
    //     });
    // },
    async userMedia() {
      try {
          await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            return true
      } catch (error) {
        setTimeout(() => {
          this.$store.dispatch("common/SET_ALERTMESSAGE", {
            iconClass: "icon-info",
            msg: "Please Enable Microphone Permission",
            class: "sa-a-danger",
          });
        }, 1000);
        this.$parent.wifiFlag = false;
        return false
      }
    },
    socketDisconnect(){
      console.log('socket disconnected')
    },
    socketConnect() {
      console.log("socket connect...");
      this.socketId = this.socket.id;
      console.log(this.socket);
      //When user accepts the call
      this.socket.on("CALL_ACCEPTED", () => {
        this.callstartflag = true
          if(this.videoData && this.videoData.data.data.provider == 'agora'){
            this.$refs.agora.joinEvent();
          }else{
              this.$refs.twilio.twilioConnect();
              this.$refs.twilio.Connected = true;
          }
        this.ringer = false;
      });
      //   When user rejects the call
      this.socket.once("CALL_REJECTED", () => {
           this.disconnectCall(true)
            this.$parent.wifiFlag = false;
            if(!this.callstartflag){
              if(this.videoData && this.videoData.data.data.provider == 'agora'){
                  this.$refs.agora.clearDialer();
              }else{
                  this.$refs.twilio.clearDialer();
              }
            }
      });
    },
    endCall(){
      if(this.videoData && this.videoData.data.data.provider == 'agora'){
                this.$refs.agora.clearDialer();
            }else{
                this.$refs.twilio.clearDialer();
            }
            this.disconnectCall()
            this.$parent.wifiFlag = false;
    },
    async disconnectCall() {
        this.$parent.wifiFlag = false;
        let body = {};
        if (this.callstartflag) {
          //End call
          body = {
            uid: this.apnInfo.createdby,
            duration: '00 : 00 : 00',
            roomName: this.apnInfo.apnt_id,
            utid: this.apnInfo.utid,
          };
        } else {
          // Missed call
          body = {
            uid: this.apnInfo.createdby,
            isMissedCall: true,
            roomName: this.apnInfo.apnt_id,
            utid: this.apnInfo.utid,
          };
        }
        await this.$store.dispatch("appointments/MISSED_CALL", body);
    }
  },
};
</script>