<template>
      <div class="sa-dialog-body sa-ringer">
        calling {{ $parent.pname }}...
        <div class="sa-decline" @click="endCall">
          <i class="icon-call2 sa-white-text"></i>
        </div>
    </div>
</template>

<script>

export default {
  name: "Ringer",
  methods: {
    endCall() {
      this.$emit("endCall");
    },
  },
};
</script>
