<template>
  <div v-if="activeAppointmentObj != null" class="sa-content">
    <div class="sa-card sa-card-info sa-card-no-border" v-if="!activeAppointmentObj.multi_patient">
      <div class="sa-card-list-box">
        <figure class="sa-avtar sa-avtar-sm">
          <img
            alt="Doctor"
            :src="activeAppointmentObj.p_img == '' ? imgUrl : activeAppointmentObj.p_img"
            class="sa-img-cover"
          />
        </figure>
        <div class="sa-doct-det sa-doct-det-sm">
          <h5>
            {{ activeAppointmentObj.p_fn }} {{ activeAppointmentObj.p_ln }}
          </h5>
          <span class="sa-black-text">{{ activeAppointmentObj.relation }}</span>
          <div class="sa-d-f-sbetween">
            <small class="sa-gray-text">{{ activeAppointmentObj.st_nm }}</small>
            <div class="sa-chat-call sa-d-flex" v-if="canStart && showBtnType !== 'completed'">
              <i
                class="icon-chat1"
                @click="$router.push('/chat-view')"
              ></i>
              <i
                class="icon-call sa-red-text"
                @click="userMedia()"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="activeAppointmentObj.multi_patient">
      <strong>Appointment Details</strong>
      <div class="sa-card sa-card-info sa-card-no-border sa-mt10">
        <div class="sa-card sa-card-no-border">
          <div class="sa-d-f-sbetween sa-ptn-det">
            <small class="sa-gray-text">{{ activeAppointmentObj.st_nm }}</small>
            <div class="sa-chat-call sa-d-flex" v-if="canStart && showBtnType !== 'completed'">
              <i class="icon-chat1" @click="$router.push('/chat-view')"></i>
              <i class="icon-call sa-red-text" @click="userMedia()"></i>
            </div>
          </div>
          <ul class="sa-apt-list">
            <li><i class="icon-calendar-gray"></i> {{activeAppointmentObj.date}}</li>
            <li><i class="icon-time2"></i> {{activeAppointmentObj.strt_tym}} to {{activeAppointmentObj.end_tym}}</li>
            <li>
              <i class="icon-calendar-star sa-blue"></i>
              <span>{{ $t("apnId") }}: {{activeAppointmentObj.apnt_id}}</span>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <div v-if="callFlag">
      <div class="sa-dialog" @click="closePop">
        <div class="sa-dialog-body sa-fixed-bottom">
          <button class="sa-close-line" @click="closePop"></button>
          <h6 class="sa-mb10">{{ $t("Select call type") }}</h6>
          <ul class="sa-card-list">
            <!-- <li class="sa-network active">
              <a class="sa-sprite sa-d-f-a-center sa-network" :href="networkCall">Network call</a>
            </li> -->
            <li class="sa-wifi">
              <span class="sa-sprite sa-d-f-a-center sa-wifi" @click="wifiCall"
                >Wifi call
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <patient-details 
      :patient-details="activeAppointmentObj.patientDetails"
      :button-type="showBtnType"
      @get-reports="getReports" 
      @remove-reports="getReports" 
      @is-patients-available="getReports"
      v-if="activeAppointmentObj.multi_patient"/>
    <appointment-info
      v-if="null != activeAppointmentObj && !activeAppointmentObj.multi_patient"
    />
    <appointment-note @add-comments="addComments" :is-saved="isApntSaved" :button-type="showBtnType"/>
    <div
      class="sa-status-card"
      v-if="(showBtnType == 'completing' || showBtnType == 'collected') && canStart && !activeAppointmentObj.multi_patient"
    >
      <div class="sa-status-card-cnt">
        {{ $t("patientNtAvlbleUnableToCompltTask") }}
      </div>
      <div class="sa-status-card-ico">
        <div class="sa-switch">
          <input
            @change="ptntNtAvlFlag = !ptntNtAvlFlag"
            type="checkbox"
            id="notific"
          />
          <label for="notific">
            <span class="sa-input-box"></span>
          </label>
        </div>
      </div>
    </div>
    <multi-file-upload
      v-if="showBtnType == 'completed' && !activeAppointmentObj.multi_patient"
      v-on:file-upload-success="saveFile"
      :show-delete="true"
      @remove-file="removeFile"
      :fileData="reportsfile"
      :showUpload="true"
    />
    <footer class="sa-mt30">
      <div v-if="ptntNtAvlFlag || multiptntNtAvlFlag">
        <button
          @click="btnClick('complete')"
          class="sa-btn sa-btn-primary sa-btn-block"
        >
          {{ $t("completeApt") }}
        </button>
      </div>
      <div v-else>
        <button
          v-if="showBtnType == 'started'"
          @click="btnClick('start')"
          class="sa-btn sa-btn-primary sa-btn-block"
          :disabled="!canStart"
        >
          {{ $t("startApt") }}
        </button>
        <button
          v-if="showBtnType == 'reached'"
          @click="btnClick('reached')"
          class="sa-btn sa-btn-warning-ripple sa-btn-block"
        >
          {{ $t("reachedDestination") }}
        </button>
        <button
          v-if="showBtnType == 'collected' && activeAppointmentObj.utid == '4'"
          @click="btnClick('collectSample')"
          class="sa-btn sa-btn-success-ripple sa-btn-block"
        >
          {{ $t("sampleCollected") }}

        </button>
        <button
          v-if="showBtnType == 'completing' && activeAppointmentObj.utid == '3'"
          @click="btnClick('collectSample')"
          class="sa-btn sa-btn-success-ripple sa-btn-block"
        >
        {{ $t("taskCompleted") }}
        </button>

        <button
          v-if="showBtnType == 'completed' && !ptntNtAvlFlag"
          @click="btnClick('complete')"
          class="sa-btn sa-btn-primary sa-btn-block"
        >
          {{ !activeAppointmentObj.multi_patient ? $t("completeApt") : $t("saveApnt") }}
        </button>
      </div>
    </footer>
    <AudioCall v-if="wifiFlag"/> 
  </div>
</template>
<script>
/* eslint-disable */
import AppointmentInfo from "../../components/appointment/AppointmentInfo.vue";
import AppointmentNote from "../../components/appointment/AppointmentNote.vue";
import AudioCall from "../../components/audio-call/AudioCall.vue"
import FileUploadMultiple from "../../components/common/FileUploadMultiple.vue";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { imageStaticUrl } from '../../../config/default.json'
import PatientDetailsVue from '../../components/multipatient/PatientDetails.vue';
export default {
  components: {
    AppointmentInfo,
    AppointmentNote,
    AudioCall,
    "multi-file-upload": FileUploadMultiple,
    "patient-details": PatientDetailsVue
  },
  data() {
    return {
      isApntSaved: false,
      canStart: false,
      callFlag: false,
      activeAppointmentObj: null,
      ptntNtAvlFlag: false,
      multiptntNtAvlFlag: false,
      showBtnType: null,
      imgUrl: imageStaticUrl,
      wifiFlag: false,
      reportsfile: [],
      showAlert: false,
      comments: null,
      data: {}
    };
  },
  mounted() {
       this.getActiveAppointment()
  },
  beforeRouteLeave (to, from, next) {
    if(this.wifiFlag){
      this.showAlert = true;
      setTimeout(() => this.showAlert = false, 3000);
      next(false)
    }else{
      next();
    }
  },
  methods: {
    requestFormat: function(){
      let isEmpty = Object.keys(this.data).length === 0
      let filteredVal = isEmpty ? JSON.parse(JSON.stringify(this.activeAppointmentObj.patientDetails)) : this.data
      let flArray = [];
      filteredVal.map((el,i) => {
        flArray.push({
          available: el.available,
          booking_id: el.booking_id,
        })
        if(el.lab_reps && el.lab_reps.length){
          flArray[i].lab_reps = el.lab_reps
        }
        return el
      })
      return flArray
    },
    addComments(val){
      this.comments = val
    },
    getReports: function(val) {
      this.data = JSON.parse(JSON.stringify(val))
      this.multiptntNtAvlFlag = this.data.every( (e) =>  e.available == false);  
    },
    closePop: function() {
      this.callFlag = false;
    },
    timerfn: function(){
      let currentDate = moment.tz('Asia/Riyadh').format("YYYY-MM-DD");
      if ( this.activeAppointmentObj.apntDate.includes(currentDate) ) {
        let startTime = this.activeAppointmentObj.strt_tym.toUpperCase();
        let durationTime = moment(startTime, "HH:mm A").subtract(4, "hours").format("HH:mm A");
        setInterval(() => {
          let currentTime = moment.tz('Asia/Riyadh').format('HH:mm A');
          this.canStart = currentTime >= durationTime;
        }, 1000);
      }
    },
    wifiCall:async function() {
      (this.wifiFlag = true), (this.callFlag = false);
       
    },
     async userMedia() {
       this.callFlag = true
      try {
          await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
      } catch (error) {
        setTimeout(() => {
          this.$store.dispatch("common/SET_ALERTMESSAGE", {
            iconClass: "icon-info",
            msg: "Please Enable Microphone Permission",
            class: "sa-a-danger",
          });
        }, 1000);
        this.wifiFlag = false;
      }
    },
    getActiveAppointment:async function () {
      let _self = this
      _self.$store.dispatch("common/SET_LOADING", true);
      const { status, data } =await _self.$store.dispatch(
        "appointments/GET_ACTIVE_APPOINTMENTS"
      )
        if (status == 1000 && data != undefined) {
          _self.activeAppointmentObj =
            data.info != undefined && data.info != null ? data.info : null;
          _self.showBtnType = data.nextEvent;
          localStorage.setItem(
            "activeAppointmentObj",
            JSON.stringify(_self.activeAppointmentObj)
          );
          _self.$store.dispatch("common/SET_LOADING", false);
          _self.timerfn()
        }
    },
    removeFile: function(file) {
      this.reportsfile = this.reportsfile.filter((_) => _.url !== file.url);
    },
    saveFile: function(val) {
      this.reportsfile.push({
        url: val.url,
        file_nme: val.filename,
        date_time: moment().format("YYYY-MM-DD HH:mm"),
      });
    },
    async btnClick(btnType) {
      let _self = this;
      let payload = null;
      switch (btnType) {
        case "start":
          payload = { status: "1", available: true };
          break;
        case "reached":
          payload = { status: "2", available: true };
          break;
        case "collectSample":
          if(!_self.activeAppointmentObj.multi_patient){
            payload = { status: "3", available: true };
          }else{
            payload = { status: "3" }
            payload.patients = await this.requestFormat()
          }
          break;
        case "complete":
          if (!_self.ptntNtAvlFlag) {
              if(_self.activeAppointmentObj.multi_patient) {
                 payload = _self.multiptntNtAvlFlag ? { status: "3" } : { status: "4" }
                if(this.comments !== null){
                  payload.t_cmpd = this.comments
                }
                payload.patients = await this.requestFormat()
                } else {
                  payload = { status: "4", available: true}
                  if(this.comments !== null){
                  payload.t_cmpd = this.comments
                }
                if (_self.reportsfile.length > 0) {
                  if(_self.activeAppointmentObj.utid == '3'){
                    payload.invsts = []
                      _self.reportsfile.forEach((v) => {
                      payload.invsts.push({
                        "file_nm": v.file_nme,
                        url: v.url,
                        dt_add: moment().format("YYYY-MM-DD"),
                      });
                    });
                  }else {
                    payload.lab_reps = [];
                      _self.reportsfile.forEach((v) => {
                      payload.lab_reps.push({
                        "file_nm": v.file_nme,
                        url: v.url,
                        dt_add: moment().format("YYYY-MM-DD"),
                      });
                    });
                  }
                }
              }
          } else {
            payload = { status: "4", available: false };
          }
      }
      const { data } = await this.$store.dispatch(
        "appointments/DO_UPDATE_STATUS",
        payload
      );
      if (data.status == 1000) {
        if (this.isCompleteApnt) {
          this.$store.dispatch("common/SET_ALERTMESSAGE", {
            iconClass: "icon-sun",
            msg: data.message || "Appointment Completed Successfully",
            class: "sa-a-success",
          });
          setTimeout(() => {
            this.$router.push("/login")
          }, 1000);
        }
          this.$store.dispatch("common/SET_ALERTMESSAGE", {
            iconClass: "icon-sun",
            msg: data.message || "Status Updated Successfully",
            class: "sa-a-success",
          });
          if(this.comments){
            this.isApntSaved = true
          }
          if(data.nextEvent){
            this.showBtnType = data.nextEvent;
          }
      } else {
        this.$store.dispatch("common/SET_ALERTMESSAGE", {
          iconClass: "icon-info",
          msg: data.error_message || "Error in updating status",
          class: "sa-a-danger",
        });
      }
      this.$store.dispatch("common/SET_LOADING", false);
    },
  },
  computed: {
    networkCall: function() {
      return `tel:+${this.activeAppointmentObj.pc}${this.activeAppointmentObj.ph}`;
    },
    isCompleteApnt: function(){
      return this.ptntNtAvlFlag || this.multiptntNtAvlFlag || (this.activeAppointmentObj.utid == '3' && this.showBtnType == 'completed' && !this.activeAppointmentObj.multi_patient)
    }
  },
};
</script>
