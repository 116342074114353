<template>
  <strong>Patient Details</strong>
  <!-- <div v-if="myFunction"></div> -->
  <div class="sa-card sa-card-info sa-card-no-border sa-mt10" >
    <div class="sa-card sa-card-no-border" v-for="patientDet in patientDetails" :key="patientDet.booking_id">
      <div class="sa-card-list-box">
        <figure class="sa-avtar sa-avtar-sm">
          <img
            alt="Doctor"
            :src="patientDet.p_img == '' ? imgUrl : patientDet.p_img"
            class="sa-img-cover"
          />
        </figure>
        <div class="sa-doct-det sa-doct-det-sm">
          <h5>{{ patientDet.p_fn }} {{ patientDet.p_ln }}</h5>
          <h6>{{ patientDet.relation[0] }}</h6>
        </div>
        <span class="sa-min-icn">
          <i
            @click="displayNone[patientDet.booking_id] = !displayNone[patientDet.booking_id]"
            :class="displayNone[patientDet.booking_id] ? 'icon-plus' : 'icon-minus' "
          ></i>
        </span>
      </div>
      <div v-show="!displayNone[patientDet.booking_id]">
        <hr class="sa-dashed-line" />
        <p v-if="selectedPatient.utid == '4'">Selected Test</p>
        <p v-if="selectedPatient.utid == '3' && selectedPatient.prd_id == 'ST036'">Selected Vaccine</p>
        <div class="sa-d-f-sbetween">
          <h6>
            {{
              selectedPatDetails(patientDet)
            }}
          </h6>
          <span @click="openSelectedDet(patientDet)" class="sa-blue-text"
            >View details</span
          >
        </div>
        <div
          class="search-location sa-mtb20"
          @click="openAttachment(patientDet)"
          v-if="selectedPatient.prd_id == 'ST04' && patientDet.available == true && isSampleCollected"
        >
          <i class="icon-attachment sa-fw500"></i> Attach investigations
          <small>(Optional)</small>
        </div>

        <div v-if="patientDet.lab_reps && patientDet.lab_reps.length > 0">
          <div
            class="sa-card sa-card-info sa-card-no-border sa-d-f-sbetween"
            v-for="(files, i) in patientDet.lab_reps"
            :key="i"
          >
            <a :href="files.url" target="_blank">
              <span
                ><i class="icon-pdf sa-red-text sa-mr10"></i
                >{{ files.file_nm }}</span
              >
            </a>
            <i class="icon-cancel" @click="closeAttachment(files)"></i>
          </div>
        </div>
        <hr class="sa-dashed-line" />
        <div class="sa-d-f-sbetween" v-if="buttonType == 'collected' || buttonType == 'completing'">
          <span class="sa-status-card-cnt">{{
            selectedPatient.utid == '4' ? $t("patientNtAvlbleUnableToColctSampl") : $t('patientNtAvlbleUnableToCompltTask')
          }}</span>
          <div class="sa-switch">
            <input
              type="checkbox"
              :id="patientDet.booking_id"
              name="booking ID"
            />
            <label :for="patientDet.booking_id">
              <span
                class="sa-input-box"
                @click="toogleFunction(patientDet)"
              ></span>
            </label>
          </div>
        </div>
        <p v-if="buttonType == 'completed' && patientDet.available == false">This Patient is Unavailable!</p>
      </div>
    </div>
  </div>
  <attachement-popup
    v-if="openAttachmentPop"
    :selected-attachments="selectedAttachments"
    @confirm-upload="addAttachments"
    v-on:close-pop="closePop"
  />
  <selecteddet-popup
    v-if="openSelectedDetPop && selectedDetails"
    :selected-details="selectedDetails"
    v-on:close-pop="closePop"
  />
</template>
<script>
/*eslint-disable*/
import AttachmentPopupVue from "../common/AttachmentPopup.vue";
import SelectedDetPopVue from "../common/SelectedDetPop.vue";
import FileUploadMultiple from "../common/FileUploadMultiple.vue";
import { imageStaticUrl } from '../../../config/default.json'
export default {
  props: ["patientDetails","buttonType"],
  emits: ["getReports","removeReports","isPatientsAvailable"],
  components: {
    "attachement-popup": AttachmentPopupVue,
    "selecteddet-popup": SelectedDetPopVue,
    "multi-file-upload": FileUploadMultiple,
  },
  data() {
    return {
      selectedDetails: null,
      selectedAttachments: null,
      openTestDetailsPop: false,
      openAttachmentPop: false,
      openSelectedDetPop: false,
      imgUrl: imageStaticUrl,
      displayNone: {},
    };
  },
  computed:{
    isSampleCollected: function() {
      return this.buttonType == "completed" ? true : false
    },
    isCovid: function(){
      return JSON.parse(localStorage.getItem("activeAppointmentObj")).covid_flag;
    },
    selectedPatient: function(){
      return this.$parent.activeAppointmentObj
    }
  },
  created() {
    for (let i = 0; i < this.patientDetails.length; i++) {
      this.displayNone[this.patientDetails[i].booking_id] = !(i == 0) ;
    }
  },
  methods: {
    selectedPatDetails: function(det) {
      if(this.selectedPatient.utid == '3'){
        for(let task of det.tasks){
          return task.name
        }
      }
      if(det.lab_packs_count == 0) {
        return `${det.test_count} Test selected`
      }else if(det.test_count == 0){
        return `${det.lab_packs_count} Package selected`
      }

      if(det.lab_packs_count > 0 && det.test_count > 0){
        return `${det.test_count} Test & ${det.lab_packs_count} Package Selected`
      }
    },
    addAttachments: async function({ attachments, id }) {
      const order = this.patientDetails.find(_ => _.booking_id == id)
        if(order){
            order.lab_reps = order.lab_reps && order.lab_reps.length > 0 ? order.lab_reps : []
            attachments.forEach((v) => {
                order.lab_reps.push({
                    file_nm: v.file_nme,
                    url:  v.url,
                    dt_add: v.dt_add
                })
            })
        } 
      this.$emit("getReports", this.patientDetails)
    },
    toogleFunction: function(val) {
      const order = this.patientDetails.find(_ => _.booking_id == val.booking_id)
      order.available = order.available ? false : true
      this.$emit("isPatientsAvailable", this.patientDetails)
    },
    openSelectedDet: function(val) {
      this.selectedDetails = val;
      this.openSelectedDetPop = true;
    },
    closePop: function() {
      this.openSelectedDetPop = false;
      this.openAttachmentPop = false
    },
    openAttachment: function(val) {
      this.selectedAttachments = val;
      this.openAttachmentPop = true;
    },
    closeAttachment: async function(val) {     
      this.patientDetails.forEach(el => {
        if(el.lab_reps){
          el.lab_reps = el.lab_reps.filter((_) => _.url !== val.url);
        }
      });
      this.$emit("removeReports", this.patientDetails)
    },
  },
};
</script>
