<template>
  <div class="sa-dialog" @click.self="$emit('close-pop')">
    <div class="sa-dialog-body sa-fixed-bottom sa-fixed-height70" >
      <button class="sa-close-line" @click="$emit('close-pop')"></button>
      <h4 class="sa-mb20">Selected Details</h4>
        <div v-if="selectedPatientDetails.packageDetails">
          <div class="sa-card"  v-for="multi in selectedPatientDetails.packageDetails" :key="multi.id">
            <!-- <h6>{{ $t("Instructions") }}</h6>
                <ul class="sa-unorder-list">
                  <li v-for="(i, index) in selectedDetails.instructions" :key="index">{{ i }}</li>
                </ul> -->
            <div class="sa-d-f-sbetween"  @click="displayNone[multi.id] = !displayNone[multi.id]">
              <h6>{{multi.name}} <small class="sa-d-block">{{multi.tests_included.length}} Test Included</small></h6>
              <span><i :class="displayNone[multi.id] ? 'icon-up-arrow' : 'icon-down-arrow'"></i></span>
            </div>
            <div class="sa-card sa-card-info sa-card-no-border sa-info-mrg" v-show="!displayNone[multi.id]">
              <strong>Tests</strong>
              <ul class="order-list">
                <li v-for="(tests,t) in multi.tests_included" :key="t">{{tests}}</li>
              </ul>
              <div v-if="multi.addl_tests_included && multi.addl_tests_included.length > 0">
                <strong>Additional test</strong>
                <ul class="order-list">
                  <li v-for="(Addtst,a) in multi.addl_tests_included" :key="a">{{Addtst}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedPatientDetails.testDetails">
          <div class="sa-card"  v-for="multi in selectedPatientDetails.testDetails" :key="multi.id">
            <div class="sa-d-f-sbetween" @click="displayNone[multi.id] = !displayNone[multi.id]">
              <h6>{{multi.name}} <small class="sa-d-block">{{multi.tests_included.length}} Test Included</small></h6>
              <span><i :class="displayNone[multi.id] ? 'icon-up-arrow' : 'icon-down-arrow'"></i></span>
            </div>
            <div class="sa-card sa-card-info sa-card-no-border sa-info-mrg" v-show="!displayNone[multi.id]">
              <strong>Tests</strong>
              <ul class="order-list">
                <li v-for="(tests,t) in multi.tests_included" :key="t">{{tests}}</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div v-if="apnInfo.utid == '3'"> -->
          <div class="sa-card sa-card-info sa-card-no-border" v-if="selectedPatientDetails && selectedPatientDetails.tasks">
            <ul class="sa-unorder-list">
              <li v-for="(i,k) in 10" :key="k">
                 Lorem ipsum dolor sit amet consectetur
              </li>
            </ul>
          </div>
        <!-- </div> -->
    </div>

  </div>
</template>
<script>
export default {
  props: {
    selectedDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
        selectedPatientDetails:  this.selectedDetails,
        displayNone:{}
    };
  },
  created() {
    if(this.selectedPatientDetails.packageDetails) {
      for( let packId of this.selectedPatientDetails.packageDetails) {
        this.displayNone[packId.id] = true
      }
    }
    if(this.selectedPatientDetails.testDetails) {
      for( let testId of this.selectedPatientDetails.testDetails) {
        this.displayNone[testId.id] = true
      }
    }
  },
};
</script>