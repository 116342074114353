<template>
  <div class="sa-dialog-body sa-video-call">
    <controls v-on:onMute="mute()" v-on:onDisconnect="leaveEvent(true)"/>
    <div class="sa-phone-hdr">
       <div class="sa-phone-hdr-tit">
        <strong>{{ apnInfo.p_fn }} {{ apnInfo.p_ln }}</strong>
        <small> {{ callingText }} </small>
       </div>
    </div>
    <div class="sa-phone-call">
        <figure class="sa-avtar sa-avtar-circle sa-avtar-xl sa-avtar-center">
          <img
            alt="doctor"
            :src="apnInfo.p_img == '' ? '' : apnInfo.p_img"
            class="sa-img-cover"
          />
        </figure>
      </div>
      <div
        @click="changeFrame()"
      >
        <streams
          :stream="localStream"
          :domId="localStream.getId()"
          v-if="localStream"
        />
      </div>
      <div
        :key="index"
        v-for="(remoteStream, index) in remoteStreams"
      >
        <streams :stream="remoteStream" :domId="remoteStream.getId()" />
      </div>
    </div>
</template>

<script>
import AgoraRTCClient from "../../../util/AgoraRTCClient";
import Streams from "./Streams.vue";
// import { bus } from '../../../main';
// import { communicationHub } from "../../../../config/default.json";
// const Controls = () => import(/* webpackChunkName: "controls" */ "../Controls.vue");
import Controls from "../Controls.vue";
export default {
  name: "AgoraClient",
  components: {
    Streams,
    Controls,
  },
  data() {
    return {
      option: {
        appid: "8c6631a1cc2948f38645017214b8ca12",
        token: "",
        uid: null,
        channel: "",
      },
      disableJoin: false,
      localStream: null,
      minimized: false,
      isPrimary: false,
      remoteStreams: [],
      localControls: {
        muted: false,
        video: false,
        audio: false,
      },
      videocallPayload: null,
      callingText: "Currently Speaking ...",
      rtc: null
    };
  },
  computed: {
    apnInfo: function () {
      return JSON.parse(localStorage.getItem("activeAppointmentObj"));
    },
  },
  async mounted() {
       await this.startCall();
       let _self = this
       this.callingLoop = setInterval(() => {
         _self.startCall();
       }, 7000)
  },
  async created () {
    console.log("agora client connected", this.apnInfo);
    
    this.rtc = new AgoraRTCClient();
    let rtc = this.rtc;
    rtc.on("stream-added", (evt) => {
      let { stream } = evt;
      console.log("[agora] [stream-added] stream-added", stream.getId());
      rtc.client.subscribe(stream);
    });

    rtc.on("stream-subscribed", (evt) => {
      let { stream } = evt;
      // console.log("[agora] [stream-subscribed] stream-added", stream.getId())
      if (!this.remoteStreams.find((it) => it.getId() === stream.getId())) {
        this.remoteStreams.push(stream);
      }
    });

    rtc.on("stream-removed", (evt) => {
      let { stream } = evt;
      console.log("[agora] [stream-removed] stream-removed", stream.getId());
      this.remoteStreams = this.remoteStreams.filter(
        (it) => it.getId() !== stream.getId()
      );
    });

    rtc.on("peer-online", (evt) => {
      console.log(`Peer ${evt.uid} is online`);
    });

    rtc.on("peer-leave", (evt) => {
      this.leaveEvent();
      // bus.$emit('DECLINE_CALL');
      console.log(`Peer ${evt.uid} already leave`);
      this.remoteStreams = this.remoteStreams.filter(
        (it) => it.getId() !== evt.uid
      );
    });
  },
  methods: {
    clearDialer() {
      if(this.callingLoop)
      clearInterval(this.callingLoop);
    },
    joinEvent() {
      this.clearDialer()
      console.log("v payload : ", this.videocallPayload);
      this.option.token = this.videocallPayload.data.token;
      this.option.channel = this.videocallPayload.data.roomName;
      if (!this.option.appid) {
        console.log("Appid");
        return;
      }
      if (!this.option.channel) {
        console.log("Channel Name");
        return;
      }
      console.log(this.option,'this.option')
      this.rtc
        .joinChannel(this.option)
        .then(() => {
          console.log({
            message: "Join Success",
            type: "success",
          });
          this.rtc
            .publishStream()
            .then((stream) => {
              console.log({
                message: "Publish Success",
                type: "success",
              });
              this.localStream = stream;
            })
            .catch((err) => {
              console.log("Publish Failure");
              console.log("publish local error", err);
            });
        })
        .catch((err) => {
          console.log("Join Failure");
          console.log("join channel error", err);
        });
      this.disableJoin = true;
    },
    mute() {
            if(!this.localControls.muted){
                this.rtc.localStream.disableAudio()
            }else{
                this.rtc.localStream.enableAudio()
            }
            this.localControls.muted = !this.localControls.muted
        },
    leaveEvent(val) {
      this.disableJoin = false;
      this.rtc
        .leaveChannel()
        .then(() => {
          console.log({
            message: "Leave Success",
            type: "success",
          });
        })
        .catch((err) => {
          console.log("Leave Failure");
          console.log("leave error", err);
        });
      this.localStream = null;
      this.remoteStreams = [];
      if(val){
        this.$emit("onDisconnect", true);
      }
    },
    changeFrame() {
      this.isPrimary = !this.isPrimary;
    },
    startCall() {
            const videocallRequestBody = {
              uid: this.apnInfo.createdby,
              doc_id: this.apnInfo.uid,
              data: {
                type: "audiocall",  
                roomName: this.apnInfo.apnt_id,
                token: this.$parent.videoData.data.data.token,
                docName: this.apnInfo.prvdr_fname + " " + this.apnInfo.prvdr_lname,
                docProfile: this.apnInfo.prvdr_img,
                name: this.apnInfo.createdby,
                sid: this.$parent.socket.id,
                uid: this.apnInfo.createdby,
                providerId: this.$parent.videoData.data.data.providerId,
                provider: this.$parent.videoData.data.data.provider,
                utid: this.apnInfo.utid
              },
            };
            this.videocallPayload = videocallRequestBody

             this.$store
            .dispatch("appointments/SOCKET_VIDEO_CALL", videocallRequestBody)
    },
  },
};
</script>
