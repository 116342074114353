<template>
  <div v-if="undefined != apnInfo && null != apnInfo">
    <div class="sa-dialog" @click.self="$emit('close-pop')">
      <div class="sa-dialog-body sa-fixed-bottom sa-fixed-height70" :draggable="true">
        <button class="sa-close-line" @click="$emit('close-pop')"></button>
        <div v-if="apnInfo.utid == '3'">
          <h5 class="sa-mb10">{{ $t("selectedTask") }}</h5>
          <div class="sa-card sa-card-info sa-card-no-border">
            <h6 class="sa-mb10">{{ $t("instructions") }}</h6>
            <ul class="sa-unorder-list">
              <li v-for="(ins, i) in selectedTask.instructions" :key="i">
                {{ ins }}
              </li>
            </ul>
          </div>
          <div v-for="(tsk, j) in selectedTask.tasks" :key="j">
            <p class="sa-black-text">{{ tsk.name }}</p>
            <ul class="sa-unorder-list">
              <li v-for="(incln, m) in selectedTask.inclusions" :key="m">
                {{ incln }}
              </li>
            </ul>
              <p class="sa-black-text">{{ $t("tskDesc") }}</p>
              <ul class="sa-unorder-list">
              <li>
                {{ tsk.desc }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t("noTaskFound") }}
  </div>
</template>
<script>
export default {
  props:['selectedTask'],
  data() {
    return {};
  },
  computed: {
    apnInfo: function() {
      return this.$store.getters["appointments/getActiveAppointments"].data.info;
    },
  },
  mounted() {},
  methods: {},
};
</script>
