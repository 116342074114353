<template>
    <ul class="sa-phone-list">
      <li @click="muteLocalParticipant" v-if="!$parent.localControls.muted">
        <i class="icon-micon"></i>
      </li>
      <li @click="muteLocalParticipant" v-if="$parent.localControls.muted">
        <i class="icon-mute"></i>
      </li>
      <li class="sa-red-bg" @click="disconnectCall">
        <i class="icon-call2 sa-white-text"></i>
      </li>
    </ul>
</template>

<script>
export default {
  name: "Controls",
  methods: {
    muteLocalParticipant() {
      this.$emit("onMute");
    },
    disconnectCall() {
      this.$emit("onDisconnect");
    },
  },
};
</script>