<template>
  <div class="sa-dialog" v-if="confirmInvPop" @click.self="$emit('close-pop')">
    <div class="sa-dialog-body sa-fixed-bottom sa-fixed-height70">
      <button class="sa-close-line" @click="$emit('close-pop')"></button>
      <h5 class="sa-mb10">Add Investigation </h5>
      <div class="sa-form-group">
        <i class="icon-down-arrow sa-form-icon"></i>
        <select class="sa-form-control" v-model="testName">
          <option  v-for="(selected,i) in selectedAttachments.tst_and_pkg" :key="i" >{{selected}}</option>
        </select>
      </div>
      <!-- <div class="sa-form-group">
        <i class="icon-down-arrow sa-form-icon"></i>
        <select class="sa-form-control">
          <option>Result</option>
        </select>
      </div> -->
      <div class="sa-form-group sa-date-picker">
        <datepicker v-model="picked" class="sa-form-control"/>
      </div>
        <multi-file-upload
          v-on:file-upload-success="saveFile"
          :show-delete="true"
          @remove-file="removeFile"
          :fileData="reportsFile"
          :showUpload="true"
        />
      <div>
      <button
        @click="confirmInv(selectedAttachments)"
        class="sa-btn sa-btn-block sa-btn-primary sa-mt20"
        :disabled="reportsFile && reportsFile.length == 0"
      >
        Confirm
      </button>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import FileUploadMultiple from "./FileUploadMultiple.vue"
import Datepicker from 'vue3-datepicker'
import moment from 'moment'
export default {
  props: ["selectedAttachments"],
  components: {
    "multi-file-upload": FileUploadMultiple,
    Datepicker 
  },
  data() {
    return {
      removeFileAttach: true,
      confirmInvPop: true,
      reportsFile: [],
      testName: this.selectedAttachments.tst_and_pkg[0],
      picked: new Date(),
      format: null
    };
  },
  computed: {
    apnInfo: function() {
      return this.$store.getters["appointments/getActiveAppointments"];
    },
  },
  methods: {
    saveFile: function(val) {
      this.reportsFile.push({
        url: val.url,
      })
    },
    removeFile(val) {
      this.reportsFile = this.reportsFile.filter((_) => _.url !== val.url)
    },
    confirmInv(val) {
      this.reportsFile.map((el) => {
        el.file_nme = this.testName,
        el.dt_add = moment(this.picked).format("YYYY-MM-DD")
      })
      this.$emit("confirmUpload", { attachments: this.reportsFile , id: val.booking_id })
      this.confirmInvPop = false;
      this.$parent.openAttachmentPop = false
    },
  },
};
</script>