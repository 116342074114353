<template>
  <div>
    <div class="sa-card sa-mt30">
      <div>
        <div v-if="apnInfo.data.info.drug_his && apnInfo.data.info.drug_his.length > 0">
          <h6>Reports</h6>
          <multi-file-upload :fileData="files"/>
        </div>
      </div>
      <div
        v-if="
          apnInfo.data.info.reason_att && apnInfo.data.info.reason_att != ''
        "
      >
        <h6 class="sa-mt10">{{ $t("voiceNote") }}</h6>
        <audio-player :audioClip="apnInfo.data.info.reason_att" />
      </div>
      <div v-if="apnInfo.data.info.reason && apnInfo.data.info.reason != ''">
        <h6 class="sa-mb5">{{ $t("textNote") }}</h6>
        <div class="sa-card sa-card-info sa-card-no-border">
          {{ apnInfo.data.info.reason }}
        </div>
      </div>
      <h6 class="sa-mb5 sa-mt10">{{ $t("location") }}</h6>
      <location-map
        v-on:getlocation="setlocation"
        :current_lat="apnInfo.data.info.lat"
        :current_lng="apnInfo.data.info.lon"
      />
      <div v-if="!apnInfo.data.info.multi_patient">
        <div v-if="apnInfo.data.info.utid == '3'">
          <h6 class="sa-mb5 sa-mt20">{{ $t("selectedTask") }}</h6>
          <div class="sa-card sa-card-info sa-card-no-border" v-for="(nursePrd,n) in apnInfo.data.info.patientDetails" :key="n">
            {{ nursePrd.task_count }} {{ $t("taskSelected") }}
            <span
              class="sa-d-block sa-blue-text sa-mt10"
              @click="openSelectedDetails(nursePrd)"
              >{{ $t("viewTaskDetails") }}</span
            >
          </div>
        </div>
        <div v-else-if="apnInfo.data.info.utid == '4'">
          <div v-for="(pack,p) in apnInfo.data.info.patientDetails" :key="p">
            <div v-if="pack.packageDetails && pack.packageDetails.length">
              <h6 class="sa-mb5 sa-mt20" v-if="pack.lab_packs_count > 0">{{ $t("selectedPackage") }}</h6>
              <div class="sa-card sa-card-info sa-card-no-border">
                {{ pack.lab_packs_count }} {{ $t("PkgSelected") }}
                <span
                  class="sa-d-block sa-blue-text sa-mt10"
                  @click="openSelectedDetails(pack)"
                  >{{ $t("viewPkgDetails") }}</span
                >
              </div>
            </div>
            <div v-else>
              <h6 class="sa-mb5 sa-mt20" v-if="pack.test_count > 0">{{ $t("selectedTest") }}</h6>
              <div class="sa-card sa-card-info sa-card-no-border">
                {{ pack.test_count }} {{ $t("testSelected") }}
                <span
                  class="sa-d-block sa-blue-text sa-mt10"
                  @click="openSelectedDetails(pack)"
                  >{{ $t("viewTestDetails") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-else>
        <div
          v-if="
            apnInfo.data.info.utid == '4' && apnInfo.data.info.packageDetails
          "
        >
          <h6 class="sa-mb5 sa-mt20">{{ $t("selectedPackage") }}</h6>
          <div class="sa-card sa-card-info sa-card-no-border">
            {{ apnInfo.data.info.test_count }} {{ $t("PkgSelected") }}
            <span
              class="sa-d-block sa-blue-text sa-mt10"
              @click="testDetails = true"
              >{{ $t("viewPkgDetails") }}</span
            >
          </div>
        </div>

        <div v-else>
          <h6 class="sa-mb5 sa-mt20">{{ $t("selectedTest") }}</h6>
          <div class="sa-card sa-card-info sa-card-no-border">
            {{ apnInfo.data.info.test_count }} {{ $t("testSelected") }}
            <span
              class="sa-d-block sa-blue-text sa-mt10"
              @click="testDetails = true"
              >{{ $t("viewTestDetails") }}</span
            >
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="isSampleCollected">
      <h6 class="sa-mb10">Task Completed</h6>
      <textarea name="comment" id="comment" rows="5" maxlength="1000" class="sa-form-control" v-model="comments" @input="inputComment"></textarea>
    </div>
    <task-details v-if="taskDetails" v-on:close-pop="closePop" :selectedTask="selectedTasks"/>
    <test-details v-if="testDetails" v-on:close-pop="closePop" :selectedPack="selectedPacks"/>
  </div>
</template>
<script>
/* eslint-disable */
import Audio from "../common/Audio.vue";
import LocationMap from "../common/LocationMap.vue";
import TaskDetails from "../common/TaskDetails.vue";
import TestDetails from "../common/TestDetails";
import FileUploadMultiple from "../../components/common/FileUploadMultiple";
import _ from 'lodash'
export default {
  components: {
    TaskDetails,
    TestDetails,
    LocationMap,
    "audio-player": Audio,
    "multi-file-upload": FileUploadMultiple
  },
  props: ["isSaved","buttonType"],
  data() {
    return {
      taskDetails: false,
      testDetails: false,
      templat: "",
      templan: "",
      comments: "",
      selectedTasks: null,
      selectedPacks: null,
    };
  },
  watch: {
    isSaved: function(newValue,oldValue) {
        this.comments = ""
  },
  },
  computed: {
    isSampleCollected: function() {
      return this.buttonType == "completed" ? true : false
    },
    apnInfo: function() {
      return this.$store.getters["appointments/getActiveAppointments"];
    },
    files: function(){
      return this.apnInfo.data.info.drug_his.map(_=> ({
        file_nme: _.file_nm,
        url: _.url
      }));
    },
  },
  mounted() {
  },
  methods: {
    openSelectedDetails: function(packOrTest){
      console.log(packOrTest,"packOrTest");
      if(( packOrTest.packageDetails && packOrTest.packageDetails.length) || (packOrTest.testDetails && packOrTest.testDetails.length)){
        this.selectedPacks = packOrTest
        this.testDetails = true
      }else {
        this.selectedTasks = packOrTest
        this.taskDetails = true
      }
    },
    inputComment: function() {
      this.$emit('addComments', this.comments)
    },
    closePop: function() {
      this.apnInfo.data.info.utid == "3"
        ? (this.taskDetails = false)
        : (this.testDetails = false);
    },
    setlocation: function(lat, long) {
      this.templat = String(lat);
      this.templan = String(long);
    },
  },
};
</script>
