<template>
  <div class="sa-rel">
    <div id="sanar-map"></div>
      <div class="sa-over-map">
        <!-- <button type="submit" @click='getMyLocation' class="sa-btn icon-current-location"></button> -->
        <div class="sa-location-map">
          <i class="sa-sprite sa-search"></i>
          <input ref="autocomplete" :placeholder="$t('search')" class="search-location" :v-model="location" :value="location" type="text" disabled/>
        </div>
      </div>
      <span class="icon-location2 sa-my-loct"></span>
  </div>
</template>
<script>
  export default {
    props:['current_lat','current_lng','locname'],
    data() {
      return {
        bound: null,
        lat: 0,
        lon: 0,
        location:"",
        text:''
      }
    },
    watch: {
      locationText(){
         this.$emit('locationselected',location)
      }
    },
    computed: {
      mapLati() {
        return this.lats
      },
      mapLong() {
        return this.long
      },
    },
    methods: {      
      _renderMap: function (lat, lon) {
        
        this.$emit("getlocation", lat, lon);
        let _self = this
        var myStyles =[
                      {
                          featureType: "poi",
                          elementType: "labels",
                          stylers: [
                                { visibility: "off" }
                          ]
                      }
                  ];
          // eslint-disable-next-line no-undef
          let map = new google.maps.Map(document.getElementById('sanar-map'), {
          center: {
            lat: parseFloat(lat),
            lng: parseFloat(lon)
          },
          zoom: 15,
          mapTypeId: "roadmap", 
          options: {
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false,
              getCurrentPosition:false,
              draggable:false, 
              animate: false,
              click:false,
              disable:true,
              clickableIcons:false,
              styles: myStyles 
            },
        }); 
        map.addListener("dragend", () => {
          this.lat = map.center.lat()
          this.long =  map.center.lng()
          let payload = {
            latitude : map.center.lat(),
            longitude :  map.center.lng()
          }
          
          this.$store.dispatch("common/GET_PLACE_NAME", payload).then(async function () {
          let value= _self.$store.getters['common/getLocationValues']
          _self.location = value.address
          })
          this.$emit('locationselected',true)
        });
        map.addListener("click", () => {
         window.open(`https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${_self.current_lat}+,+${_self.current_lng}`)
        });
      },
      _renderplace: function (place) {         
        this.$emit("getplace", place);
      },
      resetForm() {
      this.$refs["autocomplete"].value = "";
      this.$emit('locationselected',false)
    },
    },
    mounted() {      
      let _self = this
      let value= _self.$store.getters['common/getLocationValues']
      let lat = value.latitude
      let lon = value.longitude
      if(null != this.current_lat && undefined != this.current_lat && this.current_lat != '' && null != this.current_lng && undefined != this.current_lng && this.current_lng != ''){
        lat = this.current_lat
        lon = this.current_lng
      }
      _self._renderMap(lat, lon)
      let payload = {
        latitude : lat,
        longitude : lon
      }
      this.$store.dispatch("common/GET_PLACE_NAME", payload).then(async function () {
        let value= _self.$store.getters['common/getLocationValues']
        _self.location = value.address
      })
    },
  }
</script>
