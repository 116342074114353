<template>
    <div>
        <h6>{{$t('apnInfo')}}</h6>
        <ul class="sa-apt-list">
            <li><i class="icon-calendar-gray"></i>{{apnInfo.data.info.date}} </li>
            <li><i class="icon-time2"></i> {{apnInfo.data.info.strt_tym}}  - {{apnInfo.data.info.end_tym}} </li>
            <li><i class="icon-calendar-star sa-blue"></i> <span>{{$t('apnId')}}:  </span>  {{apnInfo.data.info.apnt_id}} </li>
        </ul>
  </div>
</template>
<script>
export default {
	components: {
	},
	data() {
		return {
			actAppData:null
		};
	},
	computed: {
		apnInfo: function () {
            return this.$store.getters["appointments/getActiveAppointments"];
        },
	},
	mounted() {
		
	},
	methods: {
	}
};
</script>
